import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101')
];

export const server_loads = [0,2,5];

export const dictionary = {
		"/(public)": [63],
		"/(public)/about-us": [64],
		"/(public)/add-deck": [65],
		"/(admin)/admin/extra-mana/add": [~18,[2,3]],
		"/(admin)/admin/orders/unfulfilled": [~20,[2]],
		"/(admin)/admin/orders/[seller_order_id]": [~19,[2]],
		"/(admin)/admin/payouts/edit": [~21,[2,4]],
		"/(admin)/admin/payouts/history": [~22,[2,4]],
		"/(admin)/admin/payouts/issue": [~23,[2,4]],
		"/(admin)/admin/payouts/pending": [~24,[2,4]],
		"/(admin)/admin/payouts/seller/[normalized_username]": [~25,[2,4]],
		"/(admin)/admin/supply/health": [~26,[2]],
		"/(admin)/admin/supply/health/[format]": [~27,[2]],
		"/(public)/affiliates": [66],
		"/auth": [~101],
		"/(public)/browse_sealed": [67,[9]],
		"/(public)/cards": [70,[11]],
		"/(public)/cards/set/[set_code]/[[set_name]]": [71,[11,12]],
		"/(public)/card/[card_slug]": [68],
		"/(public)/card/[set_code]/[[card_number]]/[[card_name]]": [69,[10]],
		"/(public)/cart": [72,[13]],
		"/(public)/cart/checkout": [73,[13]],
		"/(public)/cart/optimizer": [74,[13]],
		"/(public)/mail": [75],
		"/(public)/mail/error": [77],
		"/(public)/mail/notfound": [78],
		"/(public)/mail/[template]/[content]": [~76],
		"/(public)/mtg": [79],
		"/(public)/mtg/set/[set_code]": [80],
		"/(public)/mtg/set/[set_code]/[slug]": [81],
		"/(public)/optimizer": [82],
		"/(authed)/packing-slip/[order_id]": [28,[5]],
		"/(public)/privacy": [83],
		"/(public)/racist-depictions-policy": [84],
		"/(public)/redirect-after-login": [~85],
		"/(public)/reset-password": [~86],
		"/(public)/return-policy": [87],
		"/(public)/sealed/[set_code]/[[slug]]": [88,[14]],
		"/(public)/seller-info": [89],
		"/(authed)/seller": [29,[5,6]],
		"/(authed)/seller/auth/callback": [~30,[5,6]],
		"/(authed)/seller/integrations": [31,[5,6,7]],
		"/(authed)/seller/integrations/ccgseller": [~32,[5,6,7]],
		"/(authed)/seller/integrations/crystalcommerce": [~33,[5,6,7]],
		"/(authed)/seller/integrations/shopify": [34,[5,6,7]],
		"/(authed)/seller/inventory": [35,[5,6]],
		"/(authed)/seller/inventory/add/[[item_id]]": [36,[5,6]],
		"/(authed)/seller/inventory/edit/[item_id]": [37,[5,6]],
		"/(authed)/seller/inventory/import": [~38,[5,6]],
		"/(authed)/seller/orders": [39,[5,6]],
		"/(authed)/seller/orders/fulfilled": [42,[5,6]],
		"/(authed)/seller/orders/open": [43,[5,6]],
		"/(authed)/seller/orders/[order_id]": [~40,[5,6]],
		"/(authed)/seller/orders/[order_id]/report": [~41,[5,6]],
		"/(authed)/seller/payouts": [~44,[5,6]],
		"/(authed)/seller/payouts/[payout_id]": [~45,[5,6]],
		"/(authed)/seller/profile": [46,[5,6]],
		"/(authed)/seller/sales": [47,[5,6]],
		"/(authed)/seller/sales/[id]": [48,[5,6]],
		"/(authed)/settings": [49,[5,8]],
		"/(authed)/settings/addresses": [50,[5,8]],
		"/(authed)/settings/affiliates": [51,[5,8]],
		"/(authed)/settings/billing": [52,[5,8]],
		"/(authed)/settings/change-email": [53,[5,8]],
		"/(authed)/settings/change-password": [54,[5,8]],
		"/(authed)/settings/condition": [55,[5,8]],
		"/(authed)/settings/extra-mana": [56,[5,8]],
		"/(authed)/settings/notifications": [57,[5,8]],
		"/(authed)/settings/orders": [58,[5,8]],
		"/(authed)/settings/orders/report/[order_id]": [~60,[5,8]],
		"/(authed)/settings/orders/[order_id]": [~59,[5,8]],
		"/(authed)/settings/referrals": [~61,[5,8]],
		"/(public)/set/all_sets": [91],
		"/(public)/set/[set_code]/[[slug]]": [90,[15]],
		"/(public)/shopify": [~93],
		"/(public)/shopify/auth/callback": [~94],
		"/(public)/shop/[normalized_username]": [92,[16,17]],
		"/(public)/signin": [~95],
		"/(public)/signup": [~96],
		"/(authed)/support": [~62,[5]],
		"/(public)/terms": [97],
		"/(public)/thank-you/[id]": [~98],
		"/(public)/top_cards": [~99],
		"/(public)/top_cards/[format]": [~100]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';